var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"tables"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10",staticStyle:{"margin-top":"-56px !important"}},[_c('p',{staticClass:"h2 text-secondary"},[_c('i',{class:_vm.$route.meta.iconClass,staticStyle:{"font-size":"22px"}}),_c('span',{staticClass:"font-weight-light",staticStyle:{"font-size":"18px"}},[_vm._v("Nom : ")]),_c('span',{staticStyle:{"font-size":"22px"}},[_vm._v(_vm._s(_vm.taskLibelle))])])])]),_vm._m(0),_c('hr',{staticClass:"text-secondary m-0"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 grid-margin stretch-card"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body px-0 py-2"},[_c('b-table',{attrs:{"items":_vm.items,"id":"table-list","responsive":"","per-page":_vm.perPage,"current-page":_vm.currentPage,"fields":_vm.fields,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"busy":_vm.loading,"show-empty":"","striped":"","bordered":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-2"}),_c('strong',[_vm._v("Chargement...")])],1)]},proxy:true},{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center text-gray"},[_vm._v("Table vide")])]},proxy:true},{key:"cell(status)",fn:function(data){return [_c('label',{style:(`color:${
                  data.value === 'En cours'
                    ? '#4287f5'
                    : data.value === 'En Attente'
                    ? '#a35837'
                    : data.value === 'Suspendu'
                    ? 'red'
                    : data.value === 'Terminé'
                    ? '#0f7316'
                    : 'gray'
                };`)},[_vm._v(_vm._s(data.value))])]}},{key:"cell(initiative)",fn:function(data){return [_c('span',{},[_vm._v(_vm._s(data.value.libelle))])]}},{key:"cell(action)",fn:function(data){return [_c('span',{domProps:{"innerHTML":_vm._s(data.value)}})]}},{key:"cell(progress)",fn:function(data){return [_c('b-progress',{attrs:{"max":"100","show-progress":"","height":"15px"}},[_c('b-progress-bar',{style:(`background-color: ${
                    data.value > 20 && data.value <= 70
                      ? '#f5f245'
                      : data.value > 70
                      ? '#23d92c'
                      : '#f5ba45'
                  }`),attrs:{"value":data.value,"label":`${data.value}%`}})],1)]}},{key:"cell(deadline)",fn:function(data){return [_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(
                  data.item.echeance === 'N'
                    ? 'NOUVEAU'
                    : data.item.echeance === 'R'
                    ? 'RETARD'
                    : ''
                ),expression:"\n                  data.item.echeance === 'N'\n                    ? 'NOUVEAU'\n                    : data.item.echeance === 'R'\n                    ? 'RETARD'\n                    : ''\n                ",modifiers:{"hover":true}}],staticClass:"text-white text-center px-2 py-1 rounded",style:({
                  backgroundColor: _vm.dateBackgroundColor(data.item.echeance),
                })},[_vm._v(_vm._s(data.value))])]}},{key:"cell(task)",fn:function(data){return [_c('a',{attrs:{"href":data.value.link}},[_vm._v(_vm._s(data.value.title))])]}},{key:"cell(responsable)",fn:function(data){return [_c('div',[_c('b-avatar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(
                    data.value.firstname + ' ' + data.value.lastname
                  ),expression:"\n                    data.value.firstname + ' ' + data.value.lastname\n                  ",modifiers:{"hover":true}}],staticClass:"mr-1",attrs:{"variant":"primary"}},[_c('div',{staticStyle:{"width":"30px","height":"30px","border-radius":"15px","display":"flex","align-items":"center","justify-content":"center"}},[_vm._v(" "+_vm._s(data.value.firstname[0] + "." + data.value.lastname[0])+" ")])])],1)]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"d-flex"},[(data.value.includes('show'))?_c('b-button',{staticClass:"d-flex align-items-center justify-content-center",staticStyle:{"width":"40px","height":"40px"},attrs:{"variant":"light","pill":"","size":"sm"},on:{"click":function($event){return _vm.$router.push({
                      name: 'show-task',
                      params: { id: data.item.id },
                    })}}},[_c('i',{staticClass:"mdi mdi-eye"})]):_vm._e(),(data.value.includes('delete'))?_c('b-button',{staticClass:"d-flex align-items-center justify-content-center",staticStyle:{"width":"40px","height":"40px"},attrs:{"variant":"light","pill":"","size":"sm"},on:{"click":function($event){return _vm.$emit('deleteItem', data.item.id)}}},[_c('i',{staticClass:"mdi mdi-delete"})]):_vm._e()],1)]}}])}),_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"aria-controls":"table-list","align":"right"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"h4"},[_vm._v("Dernière modification :")])])])
}]

export { render, staticRenderFns }