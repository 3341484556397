<template>
  <section class="tables">
    <div class="row">
      <div class="col-10" style="margin-top: -56px !important">
        <p class="h2 text-secondary">
          <i style="font-size: 22px" :class="$route.meta.iconClass"></i>

          <span style="font-size: 18px" class="font-weight-light">Nom : </span>
          <span style="font-size: 22px">{{ taskLibelle }}</span>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p class="h4">Dernière modification :</p>
      </div>
    </div>
    <hr class="text-secondary m-0" />
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body px-0 py-2">
            <b-table
              :items="items"
              id="table-list"
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="loading"
              show-empty
              striped
              bordered
            >
              <!-- FOR LOADING ANIMATIONS -->
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-2"></b-spinner>
                  <strong>Chargement...</strong>
                </div>
              </template>
              <!-- SHOW IF TABLE IS EMPTY -->
              <template #empty>
                <div class="text-center text-gray">Table vide</div>
              </template>

              <template v-slot:cell(status)="data">
                <label
                  :style="`color:${
                    data.value === 'En cours'
                      ? '#4287f5'
                      : data.value === 'En Attente'
                      ? '#a35837'
                      : data.value === 'Suspendu'
                      ? 'red'
                      : data.value === 'Terminé'
                      ? '#0f7316'
                      : 'gray'
                  };`"
                  >{{ data.value }}</label
                >
              </template>
              <template v-slot:cell(initiative)="data">
                <span class="">{{ data.value.libelle }}</span>
              </template>

              <template v-slot:cell(action)="data">
                <span v-html="data.value"></span>
              </template>

              <template v-slot:cell(progress)="data">
                <b-progress max="100" show-progress height="15px">
                  <b-progress-bar
                    :value="data.value"
                    :label="`${data.value}%`"
                    :style="`background-color: ${
                      data.value > 20 && data.value <= 70
                        ? '#f5f245'
                        : data.value > 70
                        ? '#23d92c'
                        : '#f5ba45'
                    }`"
                  ></b-progress-bar>
                </b-progress>
              </template>

              <template v-slot:cell(deadline)="data">
                <span
                  v-b-tooltip.hover="
                    data.item.echeance === 'N'
                      ? 'NOUVEAU'
                      : data.item.echeance === 'R'
                      ? 'RETARD'
                      : ''
                  "
                  class="text-white text-center px-2 py-1 rounded"
                  :style="{
                    backgroundColor: dateBackgroundColor(data.item.echeance),
                  }"
                  >{{ data.value }}</span
                >
              </template>

              <template v-slot:cell(task)="data">
                <a :href="data.value.link">{{ data.value.title }}</a>
              </template>
              <template v-slot:cell(responsable)="data">
                <div>
                  <b-avatar
                    v-b-tooltip.hover="
                      data.value.firstname + ' ' + data.value.lastname
                    "
                    variant="primary"
                    class="mr-1"
                  >
                    <div
                      style="
                        width: 30px;
                        height: 30px;
                        border-radius: 15px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      "
                    >
                      {{
                        data.value.firstname[0] + "." + data.value.lastname[0]
                      }}
                    </div>
                  </b-avatar>
                </div>
              </template>

              <template v-slot:cell(actions)="data">
                <div class="d-flex">
                  <b-button
                    v-if="data.value.includes('show')"
                    @click="
                      $router.push({
                        name: 'show-task',
                        params: { id: data.item.id },
                      })
                    "
                    variant="light"
                    pill
                    size="sm"
                    style="width: 40px; height: 40px"
                    class="d-flex align-items-center justify-content-center"
                    ><i class="mdi mdi-eye"></i
                  ></b-button>
                  <b-button
                    v-if="data.value.includes('delete')"
                    @click="$emit('deleteItem', data.item.id)"
                    variant="light"
                    pill
                    size="sm"
                    style="width: 40px; height: 40px"
                    class="d-flex align-items-center justify-content-center"
                    ><i class="mdi mdi-delete"></i
                  ></b-button>
                </div>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
// import moment from "moment";
Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  data: function () {
    return {
      sortBy: "name",
      perPage: 10,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      fields: [
        { key: "date", label: "Date", sortable: true },
        { key: "action", label: "Actions", sortable: true },
        { key: "editeur", label: "Editeur", sortable: true },
        // { key: "actions", label: "", sortable: false },
      ],
      items: [
        {
          date: "13/01/2020",
          action: "Création",
          editeur: "S. EWORE",
        },
        {
          date: "13/01/2020",
          action: "Total ETP (5j), Responsable (H. MACHE) ",
          editeur: "S. EWORE",
        },
        {
          date: "13/01/2020",
          action: "Total ETP (8j) ",
          editeur: "S. EWORE",
        },
      ],
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  methods: {
    dateBackgroundColor: function (echeance) {
      return echeance === "N" ? "#38b000" : "#d20f35";
    },
  },
  props: {
    // items: Array,
    loading: Boolean,
    taskLibelle: {
      type: String,
      default: "",
    },
    taskId: Number,
  },
};
</script>
