<template>
  <div id="add-page" class="container-fluid rounded bg-white p-md-5 pt-2">
    <div class="d-flex">
      <p class="my-auto mr-auto">
        <i :class="$route.meta.iconClass"></i>
        <span style="font-size: 22px; color: #7f7f7f" class="font-weight-light">
          Fiche tâche :
        </span>
        <span style="font-size: 25px" class="font-weight-bold">{{
          task.libelle
        }}</span>
      </p>
      <div class="row mb-3">
        <div class="col-12">
          <modal-actions
            @close="
              $router.go($store.getters['routerHistory/fromCreation'] ? -2 : -1)
            "
          />
        </div>
      </div>
    </div>
    <!-- <div class="d-flex">
      <p
        class="ml-auto"
        style="cursor: pointer"
        @click="showHistoryModal = !showHistoryModal"
      >
        Dernière modification: {{ task.updatedAt }} par S. EWORE
      </p>
    </div> -->
    <div class="col-12 row p-0 mx-auto">
      <div class="col-12 col-md-4 p-1">
        <form class="col-12 shdow h-100" @submit.prevent="updateItem">
          <div class="d-flex justify-content-between align-items-center mb-1">
            <p
              class="h4 text-secondary my-auto mr-auto"
              style="
                margin-bottom: 6.656px !important ;
                margin-top: 6.656px !important;
              "
            >
              Informations générales
            </p>

            <button
              class="bg-white btn p-0 border-0"
              @click="(editMode = true), (editing = 'info')"
              v-if="
                ((AuthenticatedUser && TASK.responsable
                  ? AuthenticatedUser.id === TASK.responsable.id
                  : false) ||
                  isAdmin ||
                  isSuper) &&
                (!editMode || editing != 'info')
              "
              :disabled="editMode && editing != 'info'"
            >
              <i
                class="mdi mdi-file-document-edit-outline"
                style="font-size: 35px"
                :style="{
                  color: editMode && editing !== 'info' ? 'gray' : ' #007bff',
                }"
              ></i>
            </button>
            <div
              v-else-if="
                (AuthenticatedUser && TASK.responsable
                  ? AuthenticatedUser.id === TASK.responsable.id
                  : false) ||
                isAdmin ||
                isSuper
              "
              class="m-0 d-flex p-0"
            >
              <b-button
                style="min-width: 120px"
                type="submit"
                variant="secondary"
                pill
                :disabled="submitingForm"
                size="sm"
              >
                <b-spinner
                  v-if="submitingForm"
                  small
                  class="text-white"
                  label="Loading..."
                ></b-spinner>
                Confirmer</b-button
              >
              <b-button
                @click="(editMode = !editMode), cancelEdit()"
                pill
                size="sm"
                style="background-color: #adb5bd; border-color: #adb5bd"
                >Annuler</b-button
              >
            </div>
          </div>
          <hr class="mt-1 mb-4 bg-secondary" />
          <!-- SHOW SECTION  -->

          <div v-if="!editMode || editing != 'info'">
            <!-- initiative -->
            <p>
              <span> Initiative : </span
              ><span style="color: black" class="font-weight-bold">{{
                task.phase && task.phase.initiatives
                  ? task.phase.initiatives.libelle
                  : "-"
              }}</span>
            </p>
            <!-- phase -->
            <p>
              <span> Phase : </span
              ><span style="color: black" class="font-weight-bold">{{
                task.phase ? task.phase.libelle : "-"
              }}</span>
            </p>
            <!-- task -->
            <p>
              <span> Libellé: </span
              ><span style="color: black" class="font-weight-bold">{{
                task.libelle
              }}</span>
            </p>
            <!-- type -->
            <p>
              <span> Type : </span
              ><span style="color: black" class="font-weight-bold">{{
                task.type
              }}</span>
            </p>
            <!-- Description -->
            <p>
              <span> Description :</span>
              <span style="color: black" class="font-weight-bold">
                {{ task.description }}</span
              >
            </p>
            <!-- Responsable -->
            <p>
              <span> Responsable : </span>
              <span style="color: black" class="font-weight-bold">
                {{
                  task.responsable
                    ? task.responsable.firstname +
                      " " +
                      task.responsable.lastname
                    : "-"
                }}
              </span>
            </p>

            <!-- createdAt -->
            <p>
              <span> Date de création : </span
              ><span style="color: black" class="font-weight-bold">{{
                task.createdAt
              }}</span>
            </p>
            <!-- ETP -->
            <p>
              <span> Total ETP : </span>
              <span style="color: black" class="font-weight-bold"
                >{{ totalETP }} JOURS</span
              >
            </p>
          </div>

          <!-- UPDATE SECTION -->
          <div v-else>
            <b-form-group
              label="Initiative:"
              class="font-weight-bold"
              label-cols-sm="4"
              label-align-sm="left"
            >
              <div
                class="font-weight-normal p-0 form-control"
                style="padding: 0 !important; height: fit-content"
                :class="{
                  'is-invalid':
                    $v.selectedInitiative.$error &&
                    $v.selectedInitiative.$dirty,
                }"
              >
                <v-select
                  :options="initiativesList"
                  placeholder="Initiative"
                  class="bg-transparent"
                  label="text"
                  @input="selectedPhase = null"
                  v-model="selectedInitiative"
                  :reduce="(initiative) => initiative.value"
                >
                  <template #no-options> Liste vide </template>
                </v-select>
              </div>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.selectedInitiative.$dirty"
              >
                {{ !$v.selectedInitiative.required ? "Champ obligatoire" : "" }}
              </span>
              <!--  -->
            </b-form-group>

            <b-form-group
              label="Phase:"
              class="font-weight-bold"
              label-cols-sm="4"
              label-align-sm="left"
            >
              <div
                class="font-weight-normal p-0 form-control"
                style="padding: 0 !important; height: fit-content"
                :class="{
                  'is-invalid':
                    $v.selectedPhase.$error && $v.selectedPhase.$dirty,
                }"
              >
                <v-select
                  :options="phasesList"
                  placeholder="Phase"
                  v-model="selectedPhase"
                  class="bg-transparent"
                  label="text"
                  :reduce="(phase) => phase.value"
                >
                  <template #no-options> Liste vide </template>
                </v-select>
              </div>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.selectedPhase.$dirty"
              >
                {{ !$v.selectedPhase.required ? "Champ obligatoire" : "" }}
              </span>
              <!--  -->
            </b-form-group>
            <b-form-group
              label="Libellé:"
              class="font-weight-bold"
              label-cols-sm="4"
              label-align-sm="left"
            >
              <b-form-input
                type="text"
                placeholder="Libellé"
                v-model="newTask.libelle"
                class="font-weight-normal"
                :class="{
                  'is-invalid':
                    $v.newTask.libelle.$error && $v.newTask.libelle.$dirty,
                }"
              ></b-form-input>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newTask.libelle.$dirty"
              >
                {{
                  !$v.newTask.libelle.required
                    ? "Champ obligatoire"
                    : !$v.newTask.libelle.minLength
                    ? `Le champ doit contenir au moins ${$v.newTask.libelle.$params.minLength.min} caractères.`
                    : ""
                }}
              </span>
              <!--  -->
            </b-form-group>
            <b-form-group
              label="Type:"
              class="font-weight-bold"
              label-cols-sm="4"
              label-align-sm="left"
            >
              <div
                class="font-weight-normal p-0 form-control"
                style="padding: 0 !important; height: fit-content"
                :class="{
                  'is-invalid':
                    $v.newTask.type.$error && $v.newTask.type.$dirty,
                }"
              >
                <v-select
                  class="bg-transparent"
                  :options="typesTask"
                  placeholder="Type"
                  v-model="newTask.type"
                  :error="true"
                >
                  <template #no-options> Liste vide </template>
                </v-select>
              </div>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newTask.type.$dirty"
              >
                {{ !$v.newTask.type.required ? "Champ obligatoire" : "" }}
              </span>
              <!--  -->
            </b-form-group>
            <b-form-group
              label="Description:"
              class="font-weight-bold"
              label-cols-sm="4"
              label-align-sm="left"
            >
              <textarea
                type="text"
                v-model="newTask.description"
                placeholder="Description"
                class="font-weight-normal form-control"
              />
            </b-form-group>
            <b-form-group
              label="Responsable:"
              class="font-weight-bold"
              label-cols-sm="4"
              label-align-sm="left"
            >
              <div
                class="font-weight-normal p-0 form-control"
                style="padding: 0 !important; height: fit-content"
              >
                <!-- :class="{
                  'is-invalid':
                    $v.selectedResponsable.$error && $v.selectedResponsable.$dirty,
                }" -->
                <v-select
                  :options="responsables"
                  placeholder="Responsable"
                  class="bg-transparent"
                  name="responsable"
                  label="full_name"
                  :reduce="(responsable) => responsable.value"
                  v-model="selectedResponsable"
                >
                  <template #no-options> Liste vide </template>
                </v-select>
              </div>
              <!-- ERROR MESSAGES -->
              <!-- <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.selectedResponsable.$dirty"
              >
                {{ !$v.selectedResponsable.required ? "champ obligatoire" : "" }}
              </span> -->
              <!--  -->
            </b-form-group>
            <b-form-group
              label="Date de création:"
              class="font-weight-bold"
              label-cols-sm="4"
              label-align-sm="left"
            >
              <date-picker
                :value="newTask.createdAt"
                disabled
                class="col-12 p-0 font-weight-normal"
                placeholder="Date de création"
                format="DD/MM/YYYY"
              />
            </b-form-group>
          </div>
        </form>
      </div>
      <div class="col-12 col-md-4 p-1">
        <form class="col-12 shdow h-100" @submit.prevent="updateItem">
          <div class="d-flex justify-content-between align-items-center mb-1">
            <p
              class="h4 text-secondary my-auto mr-auto"
              style="
                margin-bottom: 6.656px !important ;
                margin-top: 6.656px !important;
              "
            >
              Avancement
            </p>

            <button
              class="p-0 border-0 btn bg-white"
              @click="(editMode = true), (editing = 'avancement')"
              v-if="
                ((AuthenticatedUser && TASK.responsable
                  ? AuthenticatedUser.id === TASK.responsable.id
                  : false) ||
                  isAdmin ||
                  isSuper) &&
                (!editMode || editing != 'avancement')
              "
              :disabled="editMode && editing != 'avancement'"
            >
              <i
                class="mdi mdi-file-document-edit-outline"
                style="font-size: 35px"
                :style="{
                  color:
                    editMode && editing !== 'avancement' ? 'gray' : ' #007bff',
                }"
              ></i>
            </button>
            <div
              v-else-if="
                (AuthenticatedUser && TASK.responsable
                  ? AuthenticatedUser.id === TASK.responsable.id
                  : false) ||
                isAdmin ||
                isSuper
              "
              class="m-0 d-flex p-0"
            >
              <b-button
                style="min-width: 120px"
                type="submit"
                variant="secondary"
                pill
                :disabled="submitingForm"
                size="sm"
              >
                <b-spinner
                  v-if="submitingForm"
                  small
                  class="text-white"
                  label="Loading..."
                ></b-spinner>
                Confirmer</b-button
              >
              <b-button
                @click="(editMode = !editMode), cancelEdit()"
                pill
                size="sm"
                style="background-color: #adb5bd; border-color: #adb5bd"
                >Annuler</b-button
              >
            </div>
          </div>
          <hr class="mt-1 mb-4 bg-secondary" />
          <!-- SHOW SECTION  -->

          <div v-if="!editMode || editing != 'avancement'">
            <p>
              <span> Date de début : </span>
              <span style="color: black" class="font-weight-bold">
                {{ task.startedAt }}</span
              >
            </p>
            <p>
              <span> Date fin prév : </span>
              <span
                style="color: black"
                class="font-weight-bold"
                v-b-tooltip.hover="
                  echeanceStatus(task.createdAt, task.deadline, task.status)
                "
              >
                {{ task.deadline }}</span
              >
            </p>

            <p>
              <span> Statut : </span>
              <span style="color: black" class="font-weight-bold">{{
                task.status
              }}</span>
            </p>
            <p class="d-flex">
              <span> Avancement : </span>
              <span
                style="color: black"
                class="font-weight-bold col-6 p-0 ml-1 my-auto"
              >
                <b-progress class="" max="100" show-progress height="15px">
                  <b-progress-bar
                    :value="parseFloat(TASK ? TASK.avancement : 0).toFixed(2)"
                    :label="`${parseFloat(TASK ? TASK.avancement : 0).toFixed(
                      2
                    )}%`"
                    :style="`background: ${
                      parseFloat(TASK ? TASK.avancement : 0).toFixed(2) > 20 &&
                      parseFloat(TASK ? TASK.avancement : 0).toFixed(2) <= 70
                        ? '#f5f245'
                        : parseFloat(TASK ? TASK.avancement : 0).toFixed(2) > 70
                        ? '#23d92c'
                        : '#f5ba45'
                    }`"
                  ></b-progress-bar> </b-progress
              ></span>
            </p>
            <p>
              <span> Statut échéance : </span>
              <span
                class="
                  text-white
                  font-weight-bold
                  text-center
                  px-2
                  py-1
                  rounded
                "
                :style="{
                  backgroundColor: dateBackgroundColor(
                    task.createdAt,
                    task.deadline,
                    task.status
                  ),
                }"
                >{{
                  echeanceStatus(task.createdAt, task.deadline, task.status)
                }}</span
              >
            </p>
          </div>
          <!-- UPDATE SECTION -->
          <div v-else>
            <div>
              <b-form-group
                label="Date de début:"
                class="font-weight-bold"
                label-cols-sm="4"
                label-align-sm="left"
              >
                <date-picker
                  class="col-12 p-0 font-weight-normal"
                  v-model="newTask.startedAt"
                  placeholder="Date début"
                  :input-attr="{
                    class: {
                      'is-invalid':
                        $v.newTask.startedAt.$error &&
                        $v.newTask.startedAt.$dirty,
                      ' form-control': true,
                    },
                  }"
                  format="DD/MM/YYYY"
                />

                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newTask.startedAt.$dirty"
                >
                  {{
                    !$v.newTask.startedAt.required ? "Champ obligatoire" : ""
                  }}
                </span>
                <!--  -->
              </b-form-group>
              <b-form-group
                label="Date fin prév:"
                label-cols-sm="4"
                class="font-weight-bold"
                label-align-sm="left"
              >
                <date-picker
                  class="col-12 p-0 font-weight-normal"
                  v-model="newTask.deadline"
                  placeholder="Date fin prév"
                  format="DD/MM/YYYY"
                  :input-attr="{
                    class: {
                      'is-invalid':
                        $v.newTask.deadline.$error &&
                        $v.newTask.deadline.$dirty,
                      ' form-control': true,
                    },
                  }"
                />
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newTask.deadline.$dirty"
                >
                  {{ !$v.newTask.deadline.required ? "Champ obligatoire" : "" }}

                  {{
                    !$v.newTask.deadline.required
                      ? "Champ obligatoire"
                      : $v.newTask.deadline.required &&
                        $v.newTask.deadline.$error
                      ? "La date de fin ne doit pas être antérieur à la date de début"
                      : ""
                  }}
                </span>
                <!--  -->
              </b-form-group>
              <b-form-group
                label="Statut:"
                class="font-weight-bold"
                label-cols-sm="4"
                label-align-sm="left"
              >
                <div
                  class="font-weight-normal p-0 form-control"
                  style="padding: 0 !important; height: fit-content"
                  :class="{
                    'is-invalid':
                      $v.newTask.status.$error && $v.newTask.status.$dirty,
                  }"
                >
                  <v-select
                    class="bg-transparent"
                    :options="statusTasks"
                    placeholder="Statut "
                    v-model="newTask.status"
                  >
                    <template #no-options> Liste vide </template>
                  </v-select>
                </div>

                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newTask.status.$dirty"
                >
                  {{ !$v.newTask.status.required ? "champ obligatoire" : "" }}
                </span>
                <!--  -->
              </b-form-group>
            </div>
          </div>
        </form>
      </div>
      <div class="col-12 col-md-4 p-1">
        <form class="col-12 shdow h-100" @submit.prevent="updateItem">
          <div class="d-flex justify-content-between align-items-center mb-1">
            <p
              class="h4 text-secondary my-auto mr-auto"
              style="
                margin-bottom: 6.656px !important ;
                margin-top: 6.656px !important;
              "
            >
              Livrables
            </p>

            <button
              class="bg-white btn p-0 border-0"
              @click="(editMode = true), (editing = 'livrable')"
              v-if="!editMode || editing != 'livrable'"
              :disabled="editMode && editing != 'livrable'"
            >
              <i
                class="mdi mdi-file-document-edit-outline"
                style="font-size: 35px"
                :style="{
                  color:
                    editMode && editing !== 'livrable' ? 'gray' : ' #007bff',
                }"
              ></i>
            </button>
            <div v-else class="m-0 d-flex p-0">
              <b-button
                style="min-width: 120px"
                type="submit"
                variant="secondary"
                pill
                :disabled="submitingForm"
                size="sm"
              >
                <b-spinner
                  v-if="submitingForm"
                  small
                  class="text-white"
                  label="Loading..."
                ></b-spinner>
                Confirmer</b-button
              >
              <b-button
                @click="(editMode = !editMode), cancelEdit()"
                pill
                size="sm"
                style="background-color: #adb5bd; border-color: #adb5bd"
                >Annuler</b-button
              >
            </div>
          </div>
          <hr class="mt-1 mb-4 bg-secondary" />
          <!-- SHOW SECTION  -->

          <div v-if="!editMode || editing != 'livrable'">
            <p>
              <span style="color: black" class="font-weight-bold">
                <b-list-group class="mt-3">
                  <b-list-group-item
                    v-for="(livrable, index) in TASK.livrables"
                    :key="index"
                    class="d-flex"
                    ><i
                      class="fa my-auto pr-1 fa-paperclip"
                      aria-hidden="true"
                    ></i>
                    <a target="_blank" :href="IMG_URL + livrable.fileUrl">
                      {{ livrable.name }}</a
                    >
                    <i
                      @click="deleteLivrable(livrable.id)"
                      class="fa my-auto fa-times text-danger ml-auto"
                      aria-hidden="true"
                      style="cursor: pointer"
                    ></i
                  ></b-list-group-item>
                </b-list-group>
              </span>
            </p>
          </div>
          <!-- UPDATE SECTION -->
          <div v-else>
            <b-form-group
              label=""
              class="font-weight-bold"
              label-cols-sm="0"
              label-align-sm="left"
            >
              <FilePicker @change="filePickerChanger" @click.prevent="" />
              <span style="color: black" class="font-weight-bold">
                <b-list-group class="mt-3">
                  <b-list-group-item
                    v-for="(livrable, index) in TASK.livrables"
                    :key="index"
                    class="d-flex m-0"
                    ><i
                      class="fa my-auto pr-1 fa-paperclip"
                      aria-hidden="true"
                    ></i>
                    <a :href="IMG_URL + livrable.fileUrl">
                      {{ livrable.name }}</a
                    >
                  </b-list-group-item>
                </b-list-group>
              </span>
            </b-form-group>
          </div>
        </form>
      </div>
    </div>
    <hr />
    <!-- CONTIBUTORS SECTION -->
    <b-tabs class="tab-solid tab-solid-primary mt-3 mb-0 pb-2">
      <b-tab title="Contributeurs" class="col-12 p-0">
        <ContributerTable
          :responsables="responsables"
          :contributers="contributers"
        />
      </b-tab>
    </b-tabs>
    <!-- HISTORY TABLE  -->
    <div v-if="showHistoryModal" to="modals-xyz-548">
      <Modal @close="showHistoryModal = false"
        ><HistoryTable :taskLibelle="TASK.libelle" :taskId="TASK.id" />
      </Modal>
    </div>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import ContributerTable from "./components/ContributerTable.vue";
import FilePicker from "@/components/custom/FilePicker.vue";
import Modal from "@/components/custom/Modal.vue";
import HistoryTable from "./components/HistoryTable";
import moment from "moment";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import { datePickerFormat } from "@/helpers/DatePickerFormat.js";
import { IMG_URL } from "@/helpers/services/api";
import { required, minLength } from "vuelidate/lib/validators"; //validation
export default {
  components: {
    ContributerTable,
    ModalActions,
    FilePicker,
    Modal,
    HistoryTable,
  },
  data: () => ({
    editing: "",
    IMG_URL,
    selectedInitiative: null,
    submitingForm: false,
    editMode: false,
    showHistoryModal: false,
    contributers: [],
    selectedPhase: null,
    selectedResponsable: null,
    informatedDate: new Date(),
    task: {},
    newTask: {
      echeance: "",
      libelle: "",
      description: "",
      type: "",
      contributions: [],
      responsable: "",
      deadline: "",
      status: "",
      avancement: 50,
    },
    livrables: [],
  }),
  validations: {
    selectedPhase: {
      required,
    },
    selectedInitiative: {
      required,
    },
    // selectedResponsable: {
    //   required,
    // },

    newTask: {
      libelle: {
        required,
        minLength: minLength(4),
      },
      type: {
        required,
      },
      deadline: {
        required,
        minValue(val) {
          return this.newTask.startedAt
            ? moment(val, "DD/MM/YYYY").diff(
                moment(this.newTask.startedAt, "DD/MM/YYYY")
              ) +
                1 >
                0
            : true;
        },
      },
      status: {
        required,
      },
      startedAt: {
        required,
      },
    },
  },
  methods: {
    deleteLivrable(id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("livrable/deleteLivrable", id).then(() => {
            this.$store
              .dispatch("task/fetchTask", this.$route.params.id)
              .then(() => {
                this.$store.dispatch("task/updateTask", {
                  id: this.$route.params.id,
                });
                Swal.fire("Supprimé!", "", "success");
              });
          });
        }
      });
    },
    filePickerChanger(files) {
      this.livrables = files;
    },
    updateItem() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var item = {
          id: this.newTask.id,
          responsable: this.selectedResponsable
            ? `/api/users/${this.selectedResponsable}`
            : null,
          phase: this.selectedPhase,
          deadline: moment(this.newTask.deadline, "DD/MM/YYYY").format(
            "DD/MM/YYYY"
          ),
          startedAt: moment(this.newTask.startedAt, "DD/MM/YYYY").format(
            "DD/MM/YYYY"
          ),
          avancement: this.newTask.avancement,
          echeance: this.newTask.echeance,
          type: this.newTask.type,
          status: this.newTask.status,
          libelle: this.newTask.libelle,
          description: this.newTask.description,
        };

        if (this.$store.dispatch("task/updateTask", item)) {
          this.submitingForm = false;
          this.editMode = !this.editMode;

          Swal.fire("La tâche est mise à jour !", "", "success");
          this.task = {
            ...this.task,

            //PHASE
            phase: this.selectedPhase,
            //USER
            responsable: this.RESPONSABLES.find(
              (responsable) => responsable.id === this.selectedResponsable
            ),
            deadline: moment(this.newTask.deadline, "DD/MM/YYYY").format(
              "DD/MM/YYYY"
            ),
            avancement: this.newTask.avancement,
            echeance: this.newTask.echeance,
            type: this.newTask.type,
            status: this.newTask.status,
            libelle: this.newTask.libelle,
            description: this.newTask.description,
          };

          this.livrables.map((file, i) => {
            file.append("task", this.TASK["@id"]);
            file.append("collaborateur", this.AuthenticatedUser["@id"]);

            file.append(
              "name",
              `Livrable ${moment().format("h:m:s DD/MM/YYYY")}`
            );
            this.$store.dispatch("livrable/createLivrable", file).then(() => {
              this.$store.dispatch("task/fetchTask", this.$route.params.id); // get current task
            });
            if (i === this.livrables.length - 1) {
              Swal.fire(
                "Le fichier a été téléversé avec succès !",
                "",
                "success"
              );
            }
          });
          this.livrables = [];
        }
      }
    },
    dateBackgroundColor: function (createdAt, deadline, status) {
      var createdAt1 = moment(
        moment(createdAt, "DD/MM/YYYY h:m:s"),
        "DD-MM-YYYY"
      );

      if (status === "Terminé") return "#38b000";
      else if (
        moment
          .duration(
            moment(deadline, "DD-MM-YYYY").diff(
              moment(new Date(), "DD-MM-YYYY")
            )
          )
          .asDays() +
          1 <
        0
      )
        return "#d20f35";
      else if (
        moment
          .duration(
            moment(new Date(), "DD-MM-YYYY").diff(
              moment(createdAt1, "DD-MM-YYYY")
            )
          )
          .asDays() < 5
      )
        return "#ffd60a";
      else return "#38b000";
      // },
    },
    echeanceStatus: function (createdAt, deadline, status) {
      var createdAt1 = moment(
        moment(createdAt, "DD/MM/YYYY h:m:s"),
        "DD-MM-YYYY"
      );

      if (status === "Terminé") return "Respectée";
      else if (
        moment
          .duration(
            moment(deadline, "DD-MM-YYYY").diff(
              moment(new Date(), "DD-MM-YYYY")
            )
          )
          .asDays() +
          1 <
        0
      )
        return "En retard";
      else if (
        moment
          .duration(
            moment(new Date(), "DD-MM-YYYY").diff(
              moment(createdAt1, "DD-MM-YYYY")
            )
          )
          .asDays() < 5
      )
        return "En cours";
      else return "Nouveau";
    },
    cancelEdit() {
      this.$store.dispatch("task/fetchTask", this.$route.params.id); // get current task
    },
  },

  created() {
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("cStatus/fetchAllStatusByType", "Task");
    this.$store.dispatch("cType/fetchTaskTypes");
    this.$store.dispatch("initiative/fetchAllInitiatives");
    this.$store.dispatch("phase/fetchAllPhases");
    this.$store.dispatch("task/fetchTask", this.$route.params.id).then(() => {
      this.$store.dispatch("loader/toggleLoading", false);
    }); // get current task
  },
  computed: {
    ...mapGetters("users", ["RESPONSABLES"]), //get resposables
    ...mapGetters("cStatus", ["STATUS_TASKS"]), //get all status
    ...mapGetters("cType", ["TASK_TYPES"]), //get all status
    ...mapGetters("task", ["TASK"]), //get current task
    ...mapGetters("phase", ["PHASES"]), //get all phases
    ...mapGetters("initiative", ["INITIATIVES"]), //get all phases
    ...mapGetters([
      "isManager",
      "isArchitect",
      "isPo",
      "isAdmin",
      "isSuper",
      "isCollaborator",
      "isFinance",
      "AuthenticatedUser",
    ]),

    initiativesList() {
      return this.INITIATIVES.map((initiative) => ({
        text: initiative.libelle,
        value: initiative["@id"],
      }));
    },
    responsables() {
      return this.RESPONSABLES.map((responsable) => ({
        id: `/api/users/${responsable.id}`,
        full_name: responsable.firstname + " " + responsable.lastname,
        value: responsable.id,
        text: responsable.firstname + " " + responsable.lastname,
      }));
    },
    statusTasks() {
      return this.STATUS_TASKS;
    },
    typesTask() {
      return this.TASK_TYPES.map(({ name }) => name);
    },
    totalETP() {
      var total = 0;
      this.contributers.forEach((contributer) => {
        total = total + parseInt(contributer.nbrejours);
      });
      return !isNaN(parseInt(total)) ? parseInt(total) : 0;
    },
    taskProgress() {
      var avancement = 0;
      if (this?.TASK?.contributions?.length)
        this.TASK.contributions.map((contribute) => {
          avancement +=
            parseInt(contribute.avancement) / this.TASK.contributions.length;
        });
      return Math.floor(avancement * 100) / 100;
    },
    phasesList() {
      if (!this.selectedInitiative) return [];
      return this.INITIATIVES.find(
        (initiative) => initiative["@id"] === this.selectedInitiative
      ).phases.map((phase) => ({
        value: phase["@id"],
        text: phase.libelle,
      }));
    },
  },

  watch: {
    TASK: function () {
      this.task = {
        ...this.TASK,
        createdAt: moment(this.TASK.createdAt, "DD/MM/YYYY").format(
          "DD/MM/YYYY"
        ),
        updatedAt: moment(this.TASK.updatedAt, "DD/MM/YYYY").format(
          "DD/MM/YYYY"
        ),
        startedAt: moment(this.TASK.startedAt, "DD/MM/YYYY").format(
          "DD/MM/YYYY"
        ),
      };
      this.newTask = {
        ...this.TASK,
        createdAt: datePickerFormat(this.TASK.createdAt),
        deadline: datePickerFormat(this.TASK.deadline),
        startedAt: datePickerFormat(
          moment(this.TASK.startedAt, "DD/MM/YYYY").format("DD/MM/YYYY")
        ),
      };
      this.contributers = this.TASK.contributions
        ? this.TASK.contributions.map((contribution) => ({
            ...contribution,
            old: true,
            actions: ["edit", "delete"],
          }))
        : this.contributers;

      this.selectedPhase = this.TASK.phase["@id"];
      this.selectedInitiative = this.TASK.phase.initiatives["@id"];
      this.selectedResponsable = this.TASK.responsable
        ? this.TASK.responsable.id
        : null; //responsable
    },
    selectedInitiative() {
      if (!this.selectedInitiative) {
        this.selectedPhase = null;
      }
    },
  },
};
</script>

<style>
.mx-datepicker {
  width: 100% !important;
}
.form-row div {
  margin: auto;
}
.mx-datepicker .mx-input-wrapper .mx-input {
  height: 44.22px !important;
}
.custom-select {
  height: 44.22px !important;
}
</style>
